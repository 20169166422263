@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("http://fonts.cdnfonts.com/css/segoe-ui-4?styles=18006,18004");

* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Poppins", sans-serif;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  overflow:hidden;
  /* font-family: 'Segoe UI', sans-serif; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
