.Myprofile-wrapper {
  padding-top: 40px;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  white-space: nowrap;
  background: #f8f8fb 0% 0% no-repeat padding-box;
  height: 90vh;
  padding-left: 20% !important;
}
.Myprofile-wrapper h1 {
  font: normal normal normal 30px/66px Poppins;
  letter-spacing: 0px;
  color: #6c6c6c;
}
.Myprofile-wrapper p {
  font: normal normal medium 14px/32px Google Sans;
  letter-spacing: 0px;
  color: #111111;
  background: #f2f7ff80 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  width: 95%;
  padding: 10px;
  margin-bottom: 0px;
}
.MyProfile_form {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 95%;
  padding: 10px 10px 40px 10px;
}
.MyProfile_form img {
  width: 50%;
}
.MyProfile_form label {
  font-size: 12px;
  margin-bottom: 5px;
}
.MyProfile_form input {
  background: #e9ecff80 0% 0% no-repeat padding-box;
  border: 1px solid #e9edf0;
  border-radius: 6px;
  width:50%;
  height: 35px;
  margin-bottom: 15px;
  font-size: 11px;
  color: #08134A;
  opacity: 100%;
}
.MyProfile_form input::placeholder{
  font-size: 11px;
color: #08134A;
}