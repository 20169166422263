.Mocktest_wrapper {
  padding-top: 40px;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  white-space: nowrap;
  background-color: #f3f3fa;
  height: 90vh;
  padding-left: 19% !important;
}
.Mocktest_wrapper h5 {
  margin-bottom: 10px;
  font: normal normal normal 18px/49px Poppins;
  letter-spacing: 0px;
  color: #707070;
}
.Mocktest_wrapper_table {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 98.5%;
  padding: 20px;
}
.Mocktest_wrapper_table .form-control {
  height: 40px;
  font: normal normal normal 12px/16px Poppins;
  background: #f3f3fa 0% 0% no-repeat padding-box;
  border: 1px solid #f3f3fa;
  border-radius: 20px;
  padding-left: 30px;
}
.Mocktest_wrapper_table .serch-icon {
  display: block;
  position: relative;
  top: 5px;
  left: 32px;
  font-size: 20px;
}
.Mocktest_wrapper_table input::placeholder {
  padding-left: 20px;
}
#Mocktest_table tr th {
  font-size: 13px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  padding-bottom: 10px;
  text-align: center;
  padding-top: 30px;
}
#Mocktest_table tr {
  border-bottom: 3px solid #f8f8fb;
}
#Mocktest_table tr td {
  font-size: 12px;
  color: #2a2f44;
  font-style: Poppins;
  text-align: center;
  padding: 10px;
  opacity: 100%;
}
#mocktestsubject {
  background-color: #0072fa;
  border-radius: 25px;
  padding: 3px 20px;
  color: #fff;
}
#Mocktest_Color img {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 50%;
  width: 50%;
  height: 50%;
  color: #5a5eee;
}
/* Mock Test A Create CSS */
.mocktest_card {
  background-color: #f3f3fa;
  margin: 0 auto;
  width: 83%;
  height: 100%;
  z-index: 2;
  padding: 30px;
  position: absolute;
  top: 70px;
  right: 0%;
  overflow-y: scroll;
}
.mocktest_title {
  font: normal normal normal 20px/49px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 0.8;
}
.mocktest_back_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: #5a5eee;
  color: #fff;
  font: normal normal normal 12px/33px Poppins;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 10px;
}
