.subjectmanagement-wrapper {
  padding-top: 40px;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  white-space: nowrap;
  background-color: #f3f3fa;
  height: 90vh;
  padding-left: 18% !important;
}
.subjectmanagement_header {
  margin-bottom: 20px;
}
.subjectmanagement_header h5 {
  font: normal normal normal 20px/33px Poppins;
  color: #707070;
  font-weight: 500;
}
.subjectmanagement_header button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font: normal normal normal 12px/33px Poppins;
  color: #fafafa;
  border: #5a5eee;
  width: 25%;
  margin-right: 20px;
}
.subjectmanagement_table_data {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 98.5%;
  /* padding: 0px 20px 20px 20px; */
}
.subjectmanagement_table_data .Searchbar input {
  height: 40px;
  width:95%;
  font: normal normal normal 12px/16px Poppins;
  background: #f3f3fa 0% 0% no-repeat padding-box;
  border: 1px solid #f3f3fa;
  border-radius: 20px;
  padding-left: 20px;
  margin-left: 20px;
}
.subjectmanagement_table_data input::placeholder {
  padding-left: 20px;
}
.subjectmanagement_table_data .serch-icon {
  /* display: block; */
  position: relative;
  top: 35px;
  left: 20px;
  font-size: 20px;
  padding-left: 10px;
}
.subjectmanagement_table_data .dropdown_data {
  /* background-color: #fdfdfd; */
  width: 95%;
  /* border: 1px solid gray; */
  border-radius: 5px;
  margin: 10px;
  padding:0px 20px 0px 20px;
  font-size: 12px;
}
#subjectmanagement_table tr th {
  /* font: normal normal medium 25px/30px Poppins !important; */
  font-size: 13px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  padding-bottom: 10px;
  text-align: center;
  padding-top: 30px;
  /* padding-right: 30px; */
}
#subjectmanagement_table tr {
  border-bottom: 3px solid #f8f8fb;
}
#subjectmanagement_table tr td {
  font-size: 12px;
  color: #2a2f44;
  font-style: Poppins;
  text-align:center;
  padding: 10px;
  opacity: 100%;
  /* font-weight: 800; */
}
#subjectmanagement_table tr td #table_title img {
  width:50px;
  height: 50px;
  border-radius: 50%;
  /* background: #5A5EEE 0% 0% no-repeat padding-box; */
  /* border: 1px solid #5A5EEE; */
}
#subjectmanagement_table tr td #subjectmanagement_table_Subject  {
  width: 60%;
  height: 23px;
  padding: 0px;
  border-radius: 8px;
}
#subjectmanagement_table {
  font-size: 13px;
}
#subjectmanagement_table tr td #subjectmanagement_table_Action {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border: #5a5eee;
  color: #fff;
  padding: 3px 5px 3px 5px;
}
#subjectmanagement_table tr td #subjectmanagement_table_CreateLesson {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: #5a5eee;
  font-size: 11px;
  color: #fff;
  /* padding: 3px 5px 3px 5px; */
}
#subjectmanagement_table tr td #subjectmanagement_table_CreateLesson_view {
  background: #00d22b 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: #00d22b;
  font-size: 11px;
  color: #fff;
  padding: 5px 8px 5px 8px;
}
.subjectmanagement_table_viewLesson button {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #666666;
  border-radius: 4px;
  font: normal normal normal 10px/26px Poppins;
  letter-spacing: 0px;
  color: #008ded;
  padding: 0px 5px 0px 5px;
  border: none;
  position: absolute;
  right: 5%;
  /* top:0px; */
  bottom: 48%;
}
/* Create New Chapter */
.subjectmanagement_card {
  background-color: #f3f3fa;
  margin: 0 auto;
  width: 83%;
  height: 100%;
  z-index: 2;
  padding: 30px;
  position: absolute;
  top: 70px;
  right: 0%;
}
.createNewchapter_back_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: #5a5eee;
  color: #fff;
  font: normal normal normal 12px/33px Poppins;
  width: 5%;
  margin-bottom: 10px;
}
.createNewchapter_card p {
  font-size: 14px;
  letter-spacing: 0px;
  color: #111111;
  background: #f2f7ff80 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  padding: 10px;
  margin-bottom: 0px;
  font-weight: 500;
}
.createNewchapter_form {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 30px 10px 40px 50px;
}
.createNewchapter_form label {
  font: normal normal normal 12px/32px Poppins;
  letter-spacing: 0px;
  color: #111111;
  margin-bottom: 10px;
}
.createNewchapter_form select {
  border: 1px solid #666666;
  border-radius: 8px;
  width: 70%;
  height: 35px;
  margin-bottom: 20px;
  /* font: normal normal normal 12px/33px Poppins; */
  font-size: 12px;
  color: #666666;
  padding-left: 10px;
  padding-right: 20px;
  
}
.createNewchapter_form select {
  padding-right: 20px;
}
.createNewchapter_form option {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #666666;
}
.createNewchapter_form input {
  border: 1px solid #666666;
  border-radius: 8px;
  width: 70%;
  font: normal normal normal 12px/20px Poppins;
  height: 35px;
  margin-bottom: 20px;
  color: #666666;
}
/* .createNewchapter_form input[type="file"]{
  border: 1px solid #666666;
  border-radius: 8px;
  width: 70%;
  font-size: 12px;
  height: 35px;
  color: #666666;
  border-color: #00d22b;
  justify-content: center;
  text-align-last:left;
  text-size-adjust: top;
  align-content:top;
  padding-bottom: 10px;
} */
#Createnewchapter_cancel {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  color: #666666;
  font-size: 12px;
}
#CreatenewChapter_save {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: #5a5eee;
  color: #fff;
  font-size: 12px;
}
.createNewchapter_form_button {
  margin-right: 10%;
  margin-top: 10%;
}
.createNewchapter_form_button button {
  margin-right: 15px;
  width: 5%;
  height: 30px;
}
/* View button Panel */
.subjectmanagement_View_card {
  background-color: #f3f3fa;
  margin: 0 auto;
  width: 83%;
  height: 100%;
  z-index: 2;
  padding: 30px;
  position: absolute;
  top: 70px;
  right: 0%;
}
.View_card_header {
  background: #f2f7ff80 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  padding: 15px 0px 0px 10px;
  margin-bottom: 0px;
}
.View_card_header p {
  font-size: 14px;
  letter-spacing: 0px;
  color: #111111;
  font-weight: 500;
}
.View_card_header button {
  float: right;
  color: #5a5eee;
  border: none;
  background: #f2f7ff80 0% 0% no-repeat padding-box;
  font-weight: 500;
  padding-right: 20px;
}
.View_back_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: #5a5eee;
  color: #fff;
  font: normal normal normal 12px/33px Poppins;
  width: 5%;
  margin-bottom: 10px;
}

.View_form {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 30px 10px 40px 50px;
}
.View_form label {
  font: normal normal normal 12px/32px Poppins;
  letter-spacing: 0px;
  color: #111111;
  margin-bottom: 10px;
}
.View_form select {
  border: 1px solid #666666;
  border-radius: 8px;
  width: 70%;
  height: 35px;
  margin-bottom: 20px;
  font: normal normal normal 12px/33px Poppins;
  color: #666666;
  padding-left: 15px;
}
.View_form option {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #666666;
}
.View_form input {
  border: 1px solid #666666;
  border-radius: 8px;
  width: 70%;
  font: normal normal normal 12px/20px Poppins;
  height: 35px;
  margin-bottom: 20px;
  color: #666666;
}
.View_form input::placeholder {
  padding-left: 15px;
}
#View_cancel {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  color: #666666;
  font-size: 12px;
}
#View_Update {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: #5a5eee;
  color: #fff;
  font-size: 12px;
}
.View_form_button {
  margin-right: 10%;
  margin-top: 5%;
}
.View_form_button button {
  margin-right: 15px;
  width: 5%;
  height: 30px;
}
/* Create New Lesson CSS */
.CreateNewLesson_card {
  background-color: #f3f3fa;
  margin: 0 auto;
  width: 83%;
  height: 100%;
  z-index: 2;
  padding: 30px;
  position: absolute;
  overflow-y: scroll;
  top: 70px;
  right: 0%;
}
.CreateNewLesson_back_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: #5a5eee;
  color: #fff;
  font: normal normal normal 12px/33px Poppins;
  width: 5%;
  margin-bottom: 10px;
}
.CreateNewLesson_card p {
  font-size: 14px;
  letter-spacing: 0px;
  color: #111111;
  background: #f2f7ff80 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  padding: 10px;
  margin-bottom: 0px;
  font-weight: 500;
}
.CreateNewLesson_form {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 30px 10px 50px 50px;
  margin-bottom: 80px;
}
.CreateNewLesson_form_line1 {
  margin-bottom: 4%;
}
.CreateNewLesson_form_line1 label {
  font: normal normal normal 14px/40px Poppins;
  letter-spacing: 0px;
  color: #111111;
}
.CreateNewLesson_form_line1 input {
  background: #f9faff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 0.65;
  width: 90%;
}
.CreateNewLesson_form_line1 input::placeholder {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #777777;
}
.CreateNewLesson_form_line2 {
  margin-bottom: 2%;
}
.CreateNewLesson_form_line2 label {
  font: normal normal normal 14px/40px Poppins;
  letter-spacing: 0px;
  color: #111111;
}
.CreateNewLesson_form_line2 input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  width: 90%;
}
.CreateNewLesson_form_line3 {
  margin-bottom: 2%;
}
.CreateNewLesson_form_line3 label {
  font: normal normal normal 14px/40px Poppins;
  letter-spacing: 0px;
  color: #111111;
  margin-bottom: 10px;
}
.rdw-editor-wrapper {
  height: fit-content;
}
.CreateNewLesson_button_form button {
  border-radius: 8px;
  width: 20%;
  margin-right: 2%;
  border: none;
  font: normal normal normal 14px/40px Poppins;
}
.CreateNewLesson_button_form {
  margin-bottom: 3%;
}
/* Video Button CSS */
.CreateNewLesson_button_form #createNewLesson_Videos {
  background: #6d71ef 0% 0% no-repeat padding-box;
  color: #ffffff;
}
/* .CreateNewLesson_button_form #createNewLesson_Videos:active{
  background: #ececec 0% 0% no-repeat padding-box;
  color: #666666;
} */
/* PPt Button CSS */
.CreateNewLesson_button_form #createNewLesson_ppt {
  background: #ececec 0% 0% no-repeat padding-box;
  color: #666666;
}
.CreateNewLesson_button_form #createNewLesson_ppt:focus,
.CreateNewLesson_button_form #createNewLesson_ppt:hover {
  background: #6d71ef 0% 0% no-repeat padding-box;
  color: #ffffff;
}
/* Sort Notes Button CSS */
.CreateNewLesson_button_form #createNewLesson_sortnotes {
  background: #ececec 0% 0% no-repeat padding-box;
  color: #666666;
}
.CreateNewLesson_button_form #createNewLesson_sortnotes:hover {
  background: #6d71ef 0% 0% no-repeat padding-box;
  color: #ffffff;
}
.CreateNewLesson_URL_form {
  margin-bottom: 2%;
}
.CreateNewLesson_URL_form input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  width: 95%;
  padding-left: 30px;
}
.CreateNewLesson_Glossery {
  border: 1px solid #707070;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 3%;
  width: 95%;
}
.CreateNewLesson_Glossery_word button {
  background: #6d71ef 0% 0% no-repeat padding-box;
  border: 1px solid #6d71ef;
  color: #fff;
  font: normal normal normal 14px/30px Poppins;
  width: 95%;
  margin-bottom: 3%;
  padding-left: 2%;
}
.CreateNewLesson_Glossery_word input {
  margin-bottom: 3%;
  width: 95%;
  border: 1px solid #707070;
}
.CreateNewLesson_Glossery_Description button {
  background: #6d71ef 0% 0% no-repeat padding-box;
  border: 1px solid #6d71ef;
  color: #fff;
  font: normal normal normal 14px/30px Poppins;
  width: 98%;
  margin-bottom: 1%;
}
.CreateNewLesson_Glossery_Description input {
  margin-bottom: 1%;
  width: 98%;
  border: 1px solid #707070;
}
.CreateNewLesson_Glossery_Example button {
  background: #6d71ef 0% 0% no-repeat padding-box;
  border: 1px solid #6d71ef;
  color: #fff;
  font: normal normal normal 14px/30px Poppins;
  width: 98%;
  margin-bottom: 1%;
}
.CreateNewLesson_Glossery_Example input {
  margin-bottom: 1%;
  width: 98%;
  border: 1px solid #707070;
}
.CreateNewLesson_Glossery_title span {
  font: normal normal normal 14px/40px Poppins;
  letter-spacing: 0px;
  color: #111111;
}
.CreateNewLesson_Glossery_title i {
  font: normal normal normal 16px/40px Poppins;
  letter-spacing: 0px;
  color: #111111;
  float: right;
  padding-right: 11%;
}
.CreateNewLesson_button_save button {
  margin-right: 5%;
  float: right;
  width: 6%;
}
#CreateNewLesson_cancel_btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  color: #666666;
  font: normal normal normal 12px/33px Poppins;
}
#CreateNewLesson_save_btn {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: 1px solid #5a5eee;
  color: #ffffff;
  font: normal normal normal 12px/33px Poppins;
}
.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 4px;
  opacity: 1;
  margin: 0 auto;
  width: 35%;
  height: 33%;
  z-index: 2;
  padding: 30px;
  position: absolute;
  top: 60%;
  right: 35%;
  text-align: center;
}
.card-content img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  margin-left: 160px;
  
  /* position: relative;
  left: 220%; */
}
.card-content i {
  float: right;
  position: absolute;
  right: 5%;
  font-size: 22px;
  color: #5a5eee;
  opacity: 0.8;
  cursor: pointer;
}
.card-content h6 {
  font: normal normal normal 14px/33px Poppins;
  letter-spacing: 0px;
  color: #6c6c6c;
  margin-bottom: 20px;
}
.card-content button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: none;
  font-size: 10px;
  letter-spacing: 0px;
  color: #fafafa;
  width: 38%;
  padding: 5px 0px 5px 0px;
}
/* View Lesson Button CSS */
.subjectmanagement_ViewLesson {
  background-color: #f3f3fa;
  margin: 0 auto;
  width: 83%;
  height: 100%;
  z-index: 2;
  padding: 30px;
  position: absolute;
  top: 70px;
  right: 0%;
}
.ViewLesson_data h6 {
  font: normal normal normal 20px/35px Poppins;
  letter-spacing: 0px;
  color: #707070;
}
.ViewLesson_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  border: none;
  font-size: 12px;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}
.create_lesson_button #create_lesson {
  width: 20%;
}
.create_lesson_button #back {
  width: 10%;
}
.ViewLesson_form {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 30px 10px 50px 50px;
  margin-bottom: 80px;
}
#ViewLesson_table tr th {
  /* font: normal normal medium 25px/30px Poppins !important; */
  font-size: 13px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  padding-bottom: 10px;
  text-align: center;
  padding-top: 30px;
}
#ViewLesson_table tr {
  border-bottom: 3px solid #f8f8fb;
}
#ViewLesson_table tr td {
  font-size: 12px;
  color: #2a2f44;
  font-style: Poppins;
  text-align: center;
  padding: 10px;
  opacity: 100%;
}
#ViewLesson_table tr td #ViewLesson_table_Description {
  background-color: #00e34b;
  border-radius: 25px;
  color: #fcf05a;
  font-size: 16px;
}
#ViewLesson_table tr td #ViewLesson_table_Video {
  background-color: #abaaac;
  border-radius: 25px;
  color: #ff8086;
  font-size: 16px;
}
#ViewLesson_table tr td #ViewLesson_table_ppt {
  background-color: #abaaac;
  border-radius: 25px;
  font-size: 16px;
  color: #c81e1e;
}
#ViewLesson_table tr td #ViewLesson_table_notes {
  background-color: #00e34b;
  border-radius: 25px;
  color: #ffc144;
  font-size: 16px;
}
#ViewLesson_table tr td #ViewLesson_table_glossary {
  background-color: #00e34b;
  border-radius: 25px;
  color: #c81e1e;
  font-size: 16px;
}
#ViewLesson_table tr td #ViewLesson_table_Action {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border: #5a5eee;
  color: #fff;
  padding: 3px 5px 3px 5px;
}
/* Description Column CSS */
.Description_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 4px;
  opacity: 1;
  margin: 0 auto;
  width: 95%;
  height: 80%;
  z-index: 2;
  padding: 0px;
  position: absolute;
  top: 5%;
  right: 2%;
  text-align: center;
}
.Description_header p {
  float: left;
  font: normal normal 600 15px/41px Poppins;
  letter-spacing: 0px;
  color: #5a5eee;
  padding: 20px 0px 0px 40px;
}
.Description_header i {
  float: right;
  color: #2a2f44;
  font-size: 20px;
  font-weight: 600;
  padding: 20px 40px 0px 0px;
}
.Description_card #horizontal_line {
  border-bottom: 3px solid #707070;
  width: 100%;
  margin-bottom: 20px;
  
  /* position: relative;
  right: 2%;
  top: -30px;
  opacity: 0.5;
  height: 3px; */
}
.Description_body_data {
  background: #f9faff 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 10px 10px;
  width: 95%;
  height: 80%;
  margin-left: 30px;
}
.Description_body_data p {
  
  align-content:right;
  text-align-last:center;
  display: block;
}
.ViewLesson_Glossery {
  border: 1px solid #707070;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 3%;
  width: 90%;
  margin-left: 5%;
  overflow-y: scroll;
}
.ViewLesson_Glossery_word button {
  background: #6d71ef 0% 0% no-repeat padding-box;
  border: 1px solid #6d71ef;
  color: #fff;
  font: normal normal normal 14px/30px Poppins;
  width: 80%;
  margin-bottom: 6%;
  padding-left: 2%;
  height: 40px;
}
.ViewLesson_Glossery_word input {
  margin-bottom: 6%;
  margin-left: 10%;
  width: 80%;
  height: 50px;
  border: 1px solid #707070;
}
.ViewLesson_Glossery_Description button {
  background: #6d71ef 0% 0% no-repeat padding-box;
  border: 1px solid #6d71ef;
  color: #fff;
  height: 40px;
  font: normal normal normal 14px/30px Poppins;
  width: 80%;
  margin-bottom: 2%;
}
.ViewLesson_Glossery_Description input {
  margin-bottom: 2%;
  width: 80%;
  border: 1px solid #707070;
  margin-left: 10%;
  height: 50px;
}
.ViewLesson_Glossery_Example button {
  background: #6d71ef 0% 0% no-repeat padding-box;
  border: 1px solid #6d71ef;
  color: #fff;
  font: normal normal normal 14px/30px Poppins;
  width: 80%;
  height: 40px;
  margin-bottom: 2%;
}
.ViewLesson_Glossery_Example input {
  margin-bottom: 2%;
  width: 80%;
  border: 1px solid #707070;
  margin-left: 10%;
  height: 50px;
}
/* Create View Lesson Edit CSS */
.viewEditLesson_card {
  background-color: #f3f3fa;
  margin: 0 auto;
  width: 83%;
  height: 100%;
  z-index: 2;
  padding: 30px;
  position: absolute;
  overflow-y: scroll;
  top: 70px;
  right: 0%;
}
.CreateNewLesson_back_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: #5a5eee;
  color: #fff;
  font: normal normal normal 12px/33px Poppins;
  width: 5%;
  margin-bottom: 10px;
}
.viewEditLesson_card_header {
  background: #f2f7ff80 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  padding: 10px;
  margin-bottom: 0px;
}
.viewEditLesson_card_header p {
  font-size: 14px;
  letter-spacing: 0px;
  color: #111111;
  font-weight: 500;
  padding-top: 10px;
  font-size: 15px;
}
.viewEditLesson_card_header button {
  border: none;
  color: #5a5eee;
  background-color: #f2f7ff80;
  float: right;
  margin-right: 10px;
  padding-top: 10px;
  font-weight: 500;
}
.CreateNewLesson_form {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 30px 10px 50px 50px;
  margin-bottom: 80px;
}
.CreateNewLesson_form_line1 {
  margin-bottom: 4%;
}
.CreateNewLesson_form_line1 label {
  font: normal normal normal 14px/40px Poppins;
  letter-spacing: 0px;
  color: #111111;
}
.CreateNewLesson_form_line1 input {
  background: #f9faff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 0.65;
  width: 90%;
}
.CreateNewLesson_form_line1 input::placeholder {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #777777;
}
.CreateNewLesson_form_line2 {
  margin-bottom: 2%;
}
.CreateNewLesson_form_line2 label {
  font: normal normal normal 14px/40px Poppins;
  letter-spacing: 0px;
  color: #111111;
}
.CreateNewLesson_form_line2 input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  width: 90%;
}
.CreateNewLesson_form_line3 {
  margin-bottom: 2%;
}
.CreateNewLesson_form_line3 label {
  font: normal normal normal 14px/40px Poppins;
  letter-spacing: 0px;
  color: #111111;
  margin-bottom: 10px;
}
.rdw-editor-wrapper {
  height: fit-content;
}
.CreateNewLesson_button_form button {
  border-radius: 8px;
  width: 20%;
  margin-right: 2%;
  border: none;
  font: normal normal normal 14px/40px Poppins;
}
.CreateNewLesson_button_form {
  margin-bottom: 3%;
}
/* Video Button CSS */
.CreateNewLesson_button_form #createNewLesson_Videos {
  background: #6d71ef 0% 0% no-repeat padding-box;
  color: #ffffff;
}
/* .CreateNewLesson_button_form #createNewLesson_Videos:active{
  background: #ececec 0% 0% no-repeat padding-box;
  color: #666666;
} */
/* PPt Button CSS */
.CreateNewLesson_button_form #createNewLesson_ppt {
  background: #ececec 0% 0% no-repeat padding-box;
  color: #666666;
}
.CreateNewLesson_button_form #createNewLesson_ppt:focus,
.CreateNewLesson_button_form #createNewLesson_ppt:hover {
  background: #6d71ef 0% 0% no-repeat padding-box;
  color: #ffffff;
}
/* Sort Notes Button CSS */
.CreateNewLesson_button_form #createNewLesson_sortnotes {
  background: #ececec 0% 0% no-repeat padding-box;
  color: #666666;
}
.CreateNewLesson_button_form #createNewLesson_sortnotes:hover {
  background: #6d71ef 0% 0% no-repeat padding-box;
  color: #ffffff;
}
.CreateNewLesson_URL_form {
  margin-bottom: 2%;
}
.CreateNewLesson_URL_form input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  width: 95%;
  padding-left: 30px;
}
.CreateNewLesson_Glossery {
  border: 1px solid #707070;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 3%;
  width: 95%;
}
.CreateNewLesson_Glossery_word button {
  background: #6d71ef 0% 0% no-repeat padding-box;
  border: 1px solid #6d71ef;
  color: #fff;
  font: normal normal normal 14px/30px Poppins;
  width: 95%;
  margin-bottom: 3%;
  padding-left: 2%;
}
.CreateNewLesson_Glossery_word input {
  margin-bottom: 3%;
  width: 95%;
  border: 1px solid #707070;
}
.CreateNewLesson_Glossery_Description button {
  background: #6d71ef 0% 0% no-repeat padding-box;
  border: 1px solid #6d71ef;
  color: #fff;
  font: normal normal normal 14px/30px Poppins;
  width: 98%;
  margin-bottom: 1%;
}
.CreateNewLesson_Glossery_Description input {
  margin-bottom: 1%;
  width: 98%;
  border: 1px solid #707070;
}
.CreateNewLesson_Glossery_Example button {
  background: #6d71ef 0% 0% no-repeat padding-box;
  border: 1px solid #6d71ef;
  color: #fff;
  font: normal normal normal 14px/30px Poppins;
  width: 98%;
  margin-bottom: 1%;
}
.CreateNewLesson_Glossery_Example input {
  margin-bottom: 1%;
  width: 98%;
  border: 1px solid #707070;
}
.CreateNewLesson_Glossery_title span {
  font: normal normal normal 14px/40px Poppins;
  letter-spacing: 0px;
  color: #111111;
}
.CreateNewLesson_Glossery_title i {
  font: normal normal normal 16px/40px Poppins;
  letter-spacing: 0px;
  color: #111111;
  float: right;
  padding-right: 11%;
}
.CreateNewLesson_button_save button {
  margin-right: 5%;
  float: right;
  width: 6%;
}
#CreateNewLesson_cancel_btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  color: #666666;
  font: normal normal normal 12px/33px Poppins;
}
#CreateNewLesson_save_btn {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: 1px solid #5a5eee;
  color: #ffffff;
  font: normal normal normal 12px/33px Poppins;
}
/* .card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 4px;
  opacity: 1;
  margin: 0 auto;
  width: 30%;
  height: 28%;
  z-index: 2;
  padding: 30px;
  position: absolute;
  top: 70%;
  right: 40%;
  text-align: center;
}
.card-content img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  position: relative;
  left: 220%;
}
.card-content i {
  float: right;
  position: absolute;
  right: 5%;
  font-size: 22px;
  color: #5a5eee;
  opacity: 0.8;
  cursor: pointer;
}
.card-content h6 {
  font: normal normal normal 14px/33px Poppins;
  letter-spacing: 0px;
  color: #6c6c6c;
}
.card-content button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: none;
  font-size: 10px;
  letter-spacing: 0px;
  color: #fafafa;
  width: 38%;
  padding: 5px 0px 5px 0px;
} */
