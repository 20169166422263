.Curriculum_wrapper {
  padding-top: 20px;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  white-space: nowrap;
  background-color: #f3f3fa;
  height: 90vh;
  padding-left: 18% !important;
}
.Curriculum_wrapper_table {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  width: 98.5%;
  padding: 0px;
}
.Curriculum_wrapper_table .form-control {
  height: 40px;
  font: normal normal normal 12px/16px Poppins;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f3f3fa;
  border-radius: 20px;
  padding-left: 30px;
}
.Curriculum_wrapper_table input::placeholder {
  padding-left: 20px;
}
.Curriculum_wrapper_table .serch-icon {
  display: block;
  position: relative;
  top: 5px;
  left: 32px;
  font-size: 20px;
}
.Curriculum_wrapper_table button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #5a5eee;
  border-radius: 4px;
  color: #5a5eee;
  font: normal normal normal 12px/35px Poppins;
  padding: 0px 10px;
}
.box_2 {
  margin: 0px;
  position: relative;
}
input[type="checkbox"].switch_2 {
  font-size: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 38px;
  height: 20px;
  background: #ddd;
  border-radius: 3em;
  position: absolute;
  right: 20px;
  top: -4px;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
input[type="checkbox"].switch_2:checked {
  background: #4bbb46;
}
input[type="checkbox"].switch_2:after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.Curriculum_year-tag {
  margin-top: 3%;
  margin-left: 30px;
  background-color: #ffffff;
  border: 1px solid #5a5eee;
  border-radius: 8px;
  padding: 13px 3px 0px 3px;
}
input[type="checkbox"].switch_2:checked:after {
  left: calc(100% - 0.7em);
}
.switch_box2 label {
  padding-left: 15px;
  position: relative;
  font-size: 13px;
  position: relative;
  top: -8px;
  color: #111;
}
.Curriculum_body {
  margin-top: 40px;
}
#curriculum_table {
  width: 75%;
  margin-left: 30px;
  background-color: #fff;
}
#curriculum_table tr th {
  font-size: 14px;
  padding: 10px 0px 10px 10px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  text-align: left;
  background: #e9ecff 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
}
#curriculum_table tr td {
  font-size: 12px;
  padding: 10px 0px 10px 10px;
  color: #2a2f44;
  font-weight: 500;
  font-style: Poppins;
  text-align: left;
  border: 2px solid #08134a1a;
  border-left: 1px solid #08134a1a;
  border-right: 1px solid #08134a1a;
  opacity: 100%;
  /* background-color: #ffffff; */
}
#curriculum_table .active {
  background: #0075ff 0% 0% no-repeat padding-box;
  border: 1px solid #08134a1a;
}
#curriculum_table2 {
  width: 89%;
  background-color: #fff;
}
#curriculum_table2 tr th {
  font-size: 14px;
  padding: 10px 0px 13px 10px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  text-align: left;
  background: #e9ecff 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
}
#curriculum_table2 tr td {
  font-size: 12px;
  padding: 5px 0px 4px 20px;
  color: #3e4954;
  font-weight: 500;
  font-style: Poppins;
  text-align: left;
  border-bottom: 0.5px solid #08134a1a;
  border-radius: 0px 0px 4px 4px;
  /* border-left: 1px solid #08134a1a;
  border-right: 1px solid #08134a1a; */
}
#curriculum_table2 i {
  font-size: 20px;
  color: #23b502;
}
#curriculum_table2 button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border: 1px solid #5a5eee;
  color: #fff;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
}
/* Curriculum Unit Toggle CSS */
.Curriculum_Unit_wrapper {
  padding-top: 20px;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  white-space: nowrap;
  background-color: #f3f3fa;
  height: 90vh;
  padding-left: 18% !important;
}
.Curriculum_Unit-tag {
  margin-left: 30px;
  background-color: #ffffff;
  border: 1px solid #5a5eee;
  border-radius: 8px;
  padding: 13px 3px 0px 3px;
  width: 30%;
}
.Unit_back_button a {
  color: #fff;
  margin-right: 30px;
}
.Unit_back_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border: 1px solid #5a5eee;
  border-radius: 4px;
  color: #fff;
  font: normal normal normal 12px/29px Poppins;
  padding: 0px 10px 0px 10px;
}
.unit_form_wrapper {
  background-color: #ffffff;
  width: 95.3%;
  margin: 30px;
  padding: 20px 20px 20px 30px;
}
.unit_form_wrapper label {
  font-size: 12px;
  letter-spacing: 0px;
  color: #111111;
  font-weight: 600;
}
.unit_form_wrapper input {
  border: 1px solid #666666;
  border-radius: 5px;
  width: 90%;
  height: 32px;
  margin-top: 10px;
  font-size: 12px;
  margin-bottom: 10px;
}
.unit_form_wrapper select {
  border: 1px solid #666666;
  border-radius: 5px;
  width: 80%;
  height: 35px;
  margin-top: 10px;
  font-size: 12px;
}
.unit_form_wrapper1 {
  margin-top: 20px;
}
.unit_form_wrapper1 i {
  position: relative;
  left: 95%;
  bottom: 35px;
  color: #5a5eee;
}
.unit_form_wrapper1 input {
  border: 1px solid #666666;
  border-radius: 5px;
  width: 90%;
  height: 32px;
  /* margin-top: 10px; */
  font-size: 12px;
  /* margin-bottom: 10px; */
}

#Chapter_curriculum_table2 {
  width: 85%;
  margin-left: 30px;
  background-color: #fff;
}
#Chapter_curriculum_table2 tr th {
  font-size: 14px;
  padding: 10px 0px 13px 10px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  text-align: left;
  background: #e9ecff 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
}
#Chapter_curriculum_table2 tr td {
  font-size: 12px;
  padding: 5px 0px 4px 20px;
  color: #3e4954;
  font-weight: 900;
  font-style: Poppins;
  text-align: left;
  border-bottom: 0.5px solid #08134a1a;
  border-radius: 0px 0px 4px 4px;
  /* border-left: 1px solid #08134a1a;
  border-right: 1px solid #08134a1a; */
}
#Chapter_curriculum_table2 i {
  font-size: 20px;
  color: #23b502;
}
#Chapter_curriculum_table2 button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border: 1px solid #5a5eee;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  padding: 3px 10px 3px 10px;
}

.unit_form_wrapper_edit {
  background-color: #ffffff;
  margin: 30px 0px 0px 30px;
  width: 95.5%;
  padding: 0px;
  height: 500px;
}
.unit_form_wrapper_edit .View_unit {
  background-color: #e9ecff;
  width: 100%;
  height: 55px;
}
.View_unit p {
  padding: 18px 0px 0px 20px;
  font-size: 14px;
  color: #111111;
  font-weight: bold;
}
.View_unit button {
  border: none;
  color: #5a5eee;
  background-color: #e9ecff;
  font-size: 14px;
  padding-right: 20px;
  font-weight: 600;
}
.View_unit label{
  font-size: 12px;
  letter-spacing: 0px;
  color: #111111;
  font-weight: 600;
  padding: 20px 10px 0px 30px;
}
.View_unit select{
  border: 1px solid #666666;
  border-radius: 5px;
  width: 80%;
  height: 35px;
  margin-top: 10px;
  font-size: 12px;
  /* padding: 10px 10px 10px 10px; */
  margin-left: 30px;
}
.View_unit input{
  border: 1px solid #666666;
  border-radius: 5px;
  width: 80%;
  height: 35px;
  margin-top: 10px;
  font-size: 12px;
  /* padding: 10px 10px 10px 10px; */
  margin-left: 30px;
}
.View_unit i {
  position: relative;
  left: 93%;
  bottom: 35px;
  color: #5a5eee;
}
.unit_Save_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  font: normal normal normal 12px/25px Poppins;
  border: none;
  padding: 0px 10px;
  margin-top: 5%;
  margin-right: 2%;
}