.Chapterpaper_wrapper {
  padding-top: 40px;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  white-space: nowrap;
  background-color: #f3f3fa;
  height: 90vh;
  padding-left: 19% !important;
}
.Chapterpaper_wrapper h5 {
  margin-bottom: 10px;
  font: normal normal normal 18px/49px Poppins;
  letter-spacing: 0px;
  color: #707070;
}
.Chapterpaper_wrapper_table {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 98.5%;
  padding: 20px;
}
.Chapterpaper_wrapper_table .form-control {
  height: 40px;
  font: normal normal normal 12px/16px Poppins;
  background: #f3f3fa 0% 0% no-repeat padding-box;
  border: 1px solid #f3f3fa;
  border-radius: 20px;
  padding-left: 30px;
}
.Chapterpaper_wrapper_table .serch-icon {
  display: block;
  position: relative;
  top: 5px;
  left: 32px;
  font-size: 20px;
}
.Chapterpaper_wrapper_table input::placeholder {
  padding-left: 20px;
}
#Chapterpaper_table tr th {
  font-size: 13px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  padding-bottom: 10px;
  text-align: center;
  padding-top: 30px;
}
#Chapterpaper_table tr {
  border-bottom: 3px solid #f8f8fb;
}
#Chapterpaper_table tr td {
  font-size: 12px;
  color: #2a2f44;
  font-style: Poppins;
  text-align: center;
  padding: 10px;
  opacity: 100%;
}
#Chapterpaper_Color img {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 50%;
  width: 45%;
  height: 50%;
  color: #5a5eee;
}
#Chapterpaper_button button {
  background-color: #0075ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0px 5px;
  font-size: 12px;
}
/* ChapterDemo CSS */
.Chapter_Demo_Wrapper {
  background-color: #f7faff;
  padding: 0;
  margin: 0px;
  width: 100%;
  height: 900px;
}
.Chapter_Demo_Close_button a {
  margin: 2% 3%;
}
#Demo_Close_button_chapter {
  background: #007b85 0% 0% no-repeat padding-box;
  border: none;
  font: normal normal bold 14px/23px Poppins;
  border-radius: 35px;
  color: #fff;
  padding: 6px 25px;
  margin-right: 38px;
}
.Chapter_Demo_Wrapper #Chapter_subject {
  background: #231f20 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 0px 30px 0px 0px;
  color: #fff;
  font: normal normal bold 40px/86px Poppins;
  padding-left: 30px;
  width: 90%;
}
.Chapter_Demo_Wrapper #Chapter_keystage {
  background: #007b85 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 0px 0px 30px 0px;
  color: #fff;
  font: normal normal bold 40px/86px Poppins;
  padding-left: 30px;
  position: relative;
  bottom: 10px;
  width: 90%;
}
.chapter_fraction h4 {
  font: normal normal 600 30px/82px Poppins;
  letter-spacing: 0px;
  color: #111111;
  position: relative;
  left: 35%;
  bottom: 100px;
}
.chapter_instruction {
  background-color: #fff;
  width: 60%;
  margin-left: 25%;
  padding: 40px;
  position: relative;
  bottom: 80px;
  text-align: center;
  color: #666666;
}
.chapter_instruction h5 {
  color: #111;
  font-weight: 900;
}
.chapter_instruction ul li {
  font-size: 15px;
  letter-spacing: 0px;
  padding: 10px;
}
.chapter_instruction ul li i {
  color: #007b85;
  padding-right: 20px;
}
.Chapter_start_button a {
  color: #fff;
}
.Chapter_start_button button {
  position: relative;
  bottom: 50px;
  right: 6%;
  background: #007b85 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: none;
  color: #fff;
  font: normal normal bold 14px/35px Poppins;
  padding: 0px 10px;
}
/* ChapterPaper Selftest CSS */
.Demo_Chapter_Wrapper {
  background-color: #f7faff;
  padding: 0;
  margin: 0px;
  width: 100%;
  height: 900px;
}
#chapter_pagination {
  margin: 0;
  padding: 0;
  text-align: center;
  margin-left: 20%;
}
#chapter_pagination li {
  display: inline;
}
#chapter_pagination li a {
  border-radius: 50%;
  width: 50%;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  padding: 15px;
  color: #000;

  text-align: center;
}
#chapter_pagination li a.active {
  background-color: #007b85;
  color: #fff;
  border-radius: 50%;
  width: 50%;
}
#chapter_pagination li a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 50%;
  width: 50%;
}
.demo_Chapter_selftest {
  margin-top: 30px;
}
.demo_Chapter_selftest h6 {
  color: #007b85;
  font: normal normal normal 20px/46px Poppins;
}
.demo_Chapter_selftest time {
  background-color: #007b85;
  border: none;
  font: normal normal bold 14px/23px Poppins;
  border-radius: 25px;
  margin-left: 10px;
  text-align: center;
  padding: 15px 15px 0px 15px;
  color: #fff;
}
.Demo_Close_button_chapter i {
  /* background-color: #007b85; */
  border-radius: 5px;
  color: #fff;
  font: normal normal normal 20px/46px Poppins;
  padding: 0px 10px;
}
.demo_Chapter_question {
  background-color: #fff;
  margin-top: 5%;
  border-radius: 20px;
  padding-bottom: 0%;
  text-align: center;
}
.demo_Chapter_question img {
  width: 70%;
  padding: 20px 0px 40px 0px;
}
