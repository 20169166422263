.Worksheet_wrapper {
  padding-top: 40px;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  white-space: nowrap;
  background-color: #f3f3fa;
  height: 90vh;
  padding-left: 19% !important;
}
.Worksheet_wrapper_table {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 98.5%;
  padding: 20px;
}
.Worksheet_wrapper_table .form-control {
  height: 40px;
  font: normal normal normal 12px/16px Poppins;
  background: #f3f3fa 0% 0% no-repeat padding-box;
  border: 1px solid #f3f3fa;
  border-radius: 20px;
  padding-left: 30px;
}
.Worksheet_wrapper_table input::placeholder {
  padding-left: 20px;
}
.Worksheet_wrapper_table .serch-icon {
  display: block;
  position: relative;
  top: 5px;
  left: 32px;
  font-size: 20px;
}
#Worksheet_table tr th {
  font-size: 13px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  padding-bottom: 10px;
  text-align: center;
  padding-top: 30px;
}
#Worksheet_table tr {
  border-bottom: 3px solid #f8f8fb;
}
#Worksheet_table tr td {
  font-size: 12px;
  color: #2a2f44;
  font-style: Poppins;
  text-align: center;
  padding: 10px;
  opacity: 100%;
}
#worksheet_demo_button {
  background-color: #666666;
  color: #ccc;
  opacity: 0.5;
  border: 1px solid #666666;
}
#worksheet_create_button {
  background-color: #008ded;
  color: #fff;
  border: 1px solid #008ded;
  width: 60px;
  padding: 0px 5px 0px 5px;
}
.toggleSwitch_worksheet span span {
  display: none;
}

@media only screen {
  .toggleSwitch_worksheet {
    display: inline-block;
    height: 20px;
    position: relative;
    overflow: visible;
    padding: 0;
    /* margin-left: 50px; */
    cursor: pointer;
    width: 38px;
  }
  .toggleSwitch_worksheet * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .toggleSwitch_worksheet label,
  .toggleSwitch_worksheet > span {
    line-height: 25px;
    height: 25px;
    vertical-align: middle;
  }
  .toggleSwitch_worksheet input:focus ~ a,
  .toggleSwitch_worksheet input:focus + label {
    outline: none;
  }
  /* .toggleSwitch_worksheet label {
        position: relative;
        z-index: 3;
        display: block;
        width: 100%;
      } */
  .toggleSwitch_worksheet input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }
  .toggleSwitch_worksheet > span {
    position: absolute;
    left: -50px;
    width: 100%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
  }
  .toggleSwitch_worksheet > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: 15%;
    top: -1px;
    opacity: 0;
    /* padding-top: 2px; */
    padding-bottom: 20px;
    /* padding-right: 25px; */
  }
  .toggleSwitch_worksheet a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    height: 100%;
    padding: 0;
    left: 2px;
    width: 18px;
    background-color: #666666;
    border: 1px solid #707070;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .toggleSwitch_worksheet > span span:first-of-type {
    color: #ffffff;
    opacity: 1;
    left: 98%;
  }
  .toggleSwitch_worksheet > span:before {
    content: "";
    display: block;
    width: 80px;
    height: 100%;
    position: absolute;
    left: 50px;
    top: -2px;
    background-color: #666666;
    border: 1px solid #707070;
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }
  .toggleSwitch_worksheet input:checked ~ a {
    border-color: #fff;
    left: 100%;
    margin-left: -8px;
  }
  .toggleSwitch_worksheet input:checked ~ span:before {
    left: 40%;
    box-shadow: inset 0 0 0 30px #fff;
    border: 3px solid #5a5eee;
  }
  .toggleSwitch_worksheet input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #5a5eee;
    font-weight: 500;
    left: -40%;
  }
}

/* WorkSheet CSS */
.WorkSheet_card {
  background-color: #f3f3fa;
  margin: 0 auto;
  width: 83%;
  height: 100%;
  z-index: 2;
  padding: 30px;
  position: absolute;
  top: 70px;
  right: 0%;
  overflow-y: scroll;
}
#worksheet_title {
  font: normal normal normal 20px/49px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}
.workSheet_back_button {
  margin-right: 10px;
}
.workSheet_back_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: #5a5eee;
  color: #fff;
  font: normal normal normal 12px/33px Poppins;
  width: 100%;
  margin-bottom: 10px;
  padding: 0px 10px;
}
.WorkSheet_form {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 30px 10px 40px 50px;
}
.Worksheet_form_line1 {
  margin-bottom: 4%;
}
.Worksheet_form_line1 label {
  font: normal normal normal 14px/40px Poppins;
  letter-spacing: 0px;
  color: #111111;
}
.Worksheet_form_line1 input {
  background: #f9faff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 0.65;
  width: 90%;
}
.Worksheet_form_line1 input::placeholder {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #777777;
}
.Worksheet_form_line2 {
  margin-bottom: 2%;
}
.Worksheet_form_line2 label {
  font: normal normal normal 14px/40px Poppins;
  letter-spacing: 0px;
  color: #111111;
}
.Worksheet_form_line2 input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 0.65;
  border-radius: 8px;
  width: 90%;
}
/* .Worksheet_form_line2 input[type="time"] {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 0.65;
    border-radius: 8px;
    width: 90%;
    height: 38px;
  } */
.Worksheet_form_line2 input::placeholder {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #777777;
}
.Worksheet_QuestionType {
  background-color: #5a5eee;
  margin: 10px 20px 0px 0px;
  border-radius: 10px 10px 0px 0px;
  height: 40px;
  text-align: center;
  color: #fff;
  font: normal normal normal 14px/40px Poppins;
}
.Worksheet_QuestionType_data {
  border: 1px solid #70707080;
  margin: 0px 20px 10px 0px;
  padding: 20px 0px 80px 20px;
}
.Worksheet_QuestionType_data #MCQ {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #5a5eee;
  cursor: pointer;
}
.Worksheet_QuestionType_data #oneword:hover {
  color: #5a5eee;
}
.Worksheet_QuestionType_data #image:hover {
  color: #5a5eee;
}
.Worksheet_QuestionType_data #oneword {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #666666;
  cursor: pointer;
}
.Worksheet_QuestionType_data #image {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #666666;
  cursor: pointer;
}
.Worksheet_QuestionType_data #fillinblanks {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #ef0000;
}
.Worksheet_QuestionType_data #matchingpair {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #ef0000;
}
.Worksheet_QuestionType_data #autocheck {
  font: normal normal normal 12px/33px Poppins;
  letter-spacing: 0px;
  color: #ef0000;
}
.Worksheet_CreateNewQuestions {
  background-color: #5a5eee;
  margin: 10px 20px 0px 0px;
  border-radius: 10px 10px 0px 0px;
  height: 40px;
  color: #fff;
  padding-left: 15px;
  font: normal normal normal 14px/40px Poppins;
}
.Worksheet_CreateNewQuestions_data {
  border: 1px solid #70707080;
  margin: 0px 20px 40px 0px;
  padding: 10px 20px 40px 20px;
}
.worksheet_Questions_number label {
  font: normal normal normal 12px/29px Poppins;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 2px;
  margin-top: 10px;
}
.worksheet_Questions_number input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #70707080;
  border-radius: 4px;
  margin-bottom: 10px;
}
.Worksheet_Question_Entry label {
  font: normal normal normal 12px/29px Poppins;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 5px;
}
.rdw-editor-toolbar {
  box-shadow: 0px 3px 0px #666666;
  opacity: 0.9;
  font-size: 12px;
}
.Worksheet_Question_Answer label {
  font: normal normal normal 12px/29px Poppins;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 2px;
  margin-top: 10px;
}
.Worksheet_Question_Answer input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #70707080;
  border-radius: 4px;
  width: 620px;
  height: 30px;
  margin: 15px 15px 0px 15px;
  font-size: 12px;
}
.Worksheet_Question_Answer i {
  position: relative;
  left: 8%;
  font-size: 25px;
  top: 2px;
  color: #ff0001;
}
.Worksheet_Question_Answer select {
  width: 10%;
  height: 30px;
  margin-top: 15px;
  background-color: #5a5eee;
  font-size: 12px;
  color: #fff;
  border: 2px solid #70707080;
}
.Worksheet_Question_Answer option {
  background-color: #fff;
  color: #111111;
  border: 1px solid #70707080;
}
.toggleSwitch_worksheet_create span span {
  display: none;
}

@media only screen {
  .toggleSwitch_worksheet_create {
    display: inline-block;
    height: 25px;
    position: relative;
    overflow: visible;
    padding: 0;
    margin-left: 30px;
    cursor: pointer;
    width: 38px;
  }
  .toggleSwitch_worksheet_create * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .toggleSwitch_worksheet_create label,
  .toggleSwitch_worksheet_create > span {
    line-height: 25px;
    height: 30px;
    vertical-align: middle;
  }
  .toggleSwitch_worksheet_create input:focus ~ a,
  .toggleSwitch_worksheet_create input:focus + label {
    outline: none;
  }
  /* .toggleSwitch_worksheet label {
          position: relative;
          z-index: 3;
          display: block;
          width: 100%;
        } */
  .toggleSwitch_worksheet_create input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }
  .toggleSwitch_worksheet_create > span {
    position: absolute;
    left: -50px;
    width: 100%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
    padding-top: 30px;
  }
  .toggleSwitch_worksheet_create > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: 15%;
    top: -1px;
    opacity: 0;
  }
  .toggleSwitch_worksheet_create a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    height: 100%;
    padding: 0;
    left: 2px;
    width: 18px;
    background-color: #666666;
    border: 1px solid #707070;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .toggleSwitch_worksheet_create > span span:first-of-type {
    color: #ffffff;
    opacity: 1;
    left: 98%;
  }
  .toggleSwitch_worksheet_create > span:before {
    content: "";
    display: block;
    width: 80px;
    height: 100%;
    position: absolute;
    left: 50px;
    top: -2px;
    background-color: #666666;
    border: 1px solid #707070;
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }
  .toggleSwitch_worksheet_create input:checked ~ a {
    border-color: #fff;
    left: 100%;
    margin-left: -8px;
  }
  .toggleSwitch_worksheet_create input:checked ~ span:before {
    left: 40%;
    box-shadow: inset 0 0 0 30px #fff;
    border: 3px solid #5a5eee;
  }
  .toggleSwitch_worksheet_create input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #5a5eee;
    font-weight: 500;
    left: -40%;
  }
}
.Worksheet_Add_Answer button {
  background: #6d71ef 0% 0% no-repeat padding-box;
  border: 1px solid #6d71ef;
  color: #fff;
  font-size: 12px;
  padding: 5px 8px 5px 8px;
  position: relative;
  top: -20px;
}
.Question_buttons button {
  width: 15%;
  margin: 10px 10px 20px 10px;
}
.Question_buttons #solution {
  background: #6d71ef 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: 1px solid #6d71ef;
  color: #fff;
  font: normal normal normal 14px/40px Poppins;
}
.Question_buttons #theory {
  background: #ececec 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: 1px solid #ececec;
  color: #666666;
  font: normal normal normal 14px/40px Poppins;
}
.Question_Save_buttons button {
  width: 15%;
  margin: 10px 10px 15px 10px;
}
.Question_Save_buttons #save {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: 1px solid #6d71ef;
  color: #fff;
  font: normal normal normal 14px/40px Poppins;
}
.Question_Save_buttons #next_question {
  background: #ececec 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: 1px solid #ececec;
  color: #666666;
  font: normal normal normal 14px/40px Poppins;
}
