.Pastpaper_wrapper {
  padding-top: 40px;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  white-space: nowrap;
  background-color: #f3f3fa;
  height: 90vh;
  padding-left: 19% !important;
}
.Pastpaper_wrapper h5 {
  margin-bottom: 10px;
  font: normal normal normal 18px/49px Poppins;
  letter-spacing: 0px;
  color: #707070;
}
.Pastpaper_wrapper_table {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 98.5%;
  padding: 20px;
}
.Pastpaper_wrapper_table .form-control {
  height: 40px;
  font: normal normal normal 12px/16px Poppins;
  background: #f3f3fa 0% 0% no-repeat padding-box;
  border: 1px solid #f3f3fa;
  border-radius: 20px;
  padding-left: 30px;
}
.Pastpaper_wrapper_table .serch-icon {
  display: block;
  position: relative;
  top: 5px;
  left: 32px;
  font-size: 20px;
}
.Pastpaper_wrapper_table input::placeholder {
  padding-left: 20px;
}
#Pastpaper_table tr th {
  font-size: 13px;
  font-style: Poppins;
  font-weight: bolder;
  color: #2a2f44;
  padding-bottom: 10px;
  text-align: center;
  padding-top: 30px;
}
#Pastpaper_table tr {
  border-bottom: 3px solid #f8f8fb;
}
#Pastpaper_table tr td {
  font-size: 12px;
  color: #2a2f44;
  font-style: Poppins;
  text-align: center;
  padding: 10px;
  opacity: 100%;
}
#subject {
  background-color: #0072fa;
  border-radius: 25px;
  padding: 3px 20px;
  color: #fff;
}
#Upload_file {
  font-size: 18px;
  color: #616161;
  cursor: pointer;
}
#demo_button {
  background-color: #b2b2b2;
  padding: 3px 8px;
  border: 1px solid #b2b2b2;
  color: #fff;
  border-radius: 5px;
}
#publish_button {
  background-color: #0075ff;
  color: #fff;
  border: 1px solid #0075ff;
  padding: 3px 8px 3px 8px;
  border-radius: 5px;
}
#draft_button {
  background-color: #707070;
  color: #fff;
  border: 1px solid #707070;
  padding: 3px 8px 3px 8px;
  border-radius: 5px;
}
.open_file_container {
  background-color: #fff;
  margin: 0 auto;
  width: 49%;
  height: 38%;
  z-index: 2;
  padding: 0px;
  position: absolute;
  top: 25%;
  right: 18%;
  border: 1px solid #707070;
  box-shadow: 1px 0px 1px 0px #707070;
}
.open_file_container .Upload_file_top {
  background-color: #5a5eee;
  height: 50px;
  padding: 10px;
  margin-bottom: 20px;
}
/* .Upload_file_top {
  
  width: 843px;
  padding: 8px;
  white-space: nowrap;
  position: relative;
  top: -15%;
  right: 30px;
} */
.Upload_file_top h6 {
  font: normal normal normal 15px/27px Segoe UI;
  color: #fafafa;
}
.Upload_file_top i {
  font: normal normal normal 18px/27px Segoe UI;
  color: #fafafa;
  cursor: pointer;
}
.upload_file_input p {
  font: normal normal normal 12px/27px Segoe UI;
  color: #707070;
  margin-bottom: 0px;
  padding: 10px 10px 10px 30px;
}
.upload_file_input i {
  position: relative;
  float: right;
  top: -38px;
  padding-right: 10%;
}
.upload_file_input input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  margin: 10px 40px 5px 30px;
  width: 88%;
  font: normal normal normal 12px/27px Segoe UI;
}
.upload_file_input span {
  font: normal normal normal 12px/27px Segoe UI;
  color: #707070;
  padding: 0px 10px 10px 30px;
}
.Upload_file_buttons {
  padding-top: 10px;
  padding-right: 50px;
}
.Upload_file_buttons #cancel {
  color: #6c6c6c;
  border: none;
  background-color: #fff;
  font: normal normal normal 12px/27px Segoe UI;
  margin: 10px;
}
.Upload_file_buttons #save {
  background: #5a5eee 0% 0% no-repeat padding-box;
  color: #fff;
  font: normal normal normal 12px/27px Segoe UI;
  border: none;
  padding: 0px 15px;
  border-radius: 8px;
  margin: 10px;
}
/* Pastpaper Card Create CSS */
.PastPaper_card {
  background-color: #f3f3fa;
  margin: 0 auto;
  width: 83%;
  height: 100%;
  z-index: 2;
  padding: 30px;
  position: absolute;
  top: 70px;
  right: 0%;
  overflow-y: scroll;
}
.PastPaper_title {
  font: normal normal normal 20px/49px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.PastPaper_back_button button {
  background: #5a5eee 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: #5a5eee;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 30px;
}
.PastPaper_form {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 30px 10px 40px 50px;
}
.PastPaper_form_line1 {
  margin-bottom: 4%;
}
.PastPaper_form_line1 label {
  font: normal normal normal 14px/40px Poppins;
  letter-spacing: 0px;
  color: #111111;
}
.PastPaper_form_line1 select {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 0.65;
  width: 90%;
  font-size: 12px;
  height: 40px;
}
.PastPaper_form_line1 input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 0.65;
  width: 90%;
  font: normal normal normal 12px/28px Poppins;
  height: 40px;
}
.PastPaper_form_line1 input::placeholder {
  letter-spacing: 0px;
  color: #777777;
}
#theory:focus,
#theory:hover {
  background-color: #5a5eee;
  color: #fff;
}

/* .Image_Question_buttons button:hover{
  background-color:#5a5eee;
} */
/* Demo Page CSS */
.Demo_Wrapper {
  background-color: #f7faff;
  padding: 0;
  margin: 0px;
  width: 100%;
  height: 900px;
}
.Demo_Close_button a {
  margin: 2% 3%;
}
#Close_button {
  background: #007b85 0% 0% no-repeat padding-box;
  border: none;
  font: normal normal bold 14px/23px Poppins;
  border-radius: 35px;
  color: #fff;
  padding: 6px 25px;
  /* margin-right: 38px; */
  margin-left: 10px;
}
.Demo_Wrapper_body #demo_subject {
  background: #6ecacd 0% 0% no-repeat padding-box;
  border-radius: 0px 30px 0px 0px;
  color: #fff;
  font-size: 35px;
  width: 40%;
  padding: 20px;
}
#demo_keystage {
  background: #6ecacd 0% 0% no-repeat padding-box;
  border-radius: 0px;
  color: #fff;
  width: 40%;
  padding: 20px 20px 10px 20px;
  margin-bottom: 10px;
  position: relative;
  bottom: 35px;
}
#demo_keystage h4 {
  font-size: 20px;
  text-transform: uppercase;
  opacity: 0.9;
}
#demo_keystage h3 {
  font-size: 25px;
  font-weight: 600;
}
#demo_levels {
  background: #6ecacd 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 30px 0px;
  color: #fff;
  font-size: 35px;
  width: 40%;
  padding: 20px 20px 10px 20px;
  position: relative;
  bottom: 33px;
  /* margin-bottom: 10px; */
}
#demo_levels h4 {
  font-size: 20px;
  text-transform: uppercase;
  opacity: 0.9;
}
#demo_levels h3 {
  font-size: 25px;
  font-weight: 600;
}
#demo_year h2 {
  background: #007b85 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  color: #fff;
  width: 40%;
  font-size: 80px;
  text-transform: uppercase;
  padding: 20px;
  /* vertical-align: top;
  min-height: 148px; */
  position: relative;
  text-align: center;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  -o-writing-mode: vertical-rl;
  writing-mode: vertical-lr;
  text-transform: uppercase;
  font-size-adjust: 0.5;
  bottom: 180px;
}
#demo_sample_test {
  background: #007b85 0% 0% no-repeat padding-box;
  border-radius: 10px 0px 0px 10px;
  width: 91%;
  height: 120px;
  color: #fff;
  text-align: left;
  padding: 38px;
  position: relative;
  right: 15%;
  font: normal normal medium 32px/86px Poppins;
}
#test_Image img {
  width: 40%;
  height: 30%;
  position: relative;
  right: 10%;
  top: -10%;
}
#test_Image h2 {
  padding-left: 23%;
  padding-top: 1%;
  font: normal normal bold 40px/86px Poppins;
}
.demo_start_button button {
  position: absolute;
  top: 90%;
  left: 90%;
  background: #007b85 0% 0% no-repeat padding-box;
  border-radius: 6px;
  color: #fff;
  border: none;
  padding: 5px 15px;
}
/* Demo Delftest Create CSS */
#pagination {
  margin: 0;
  padding: 0;
  text-align: center;
}
#pagination li {
  display: inline;
}
#pagination li a {
  display: inline-block;
  text-decoration: none;
  padding: 2px 5px;
  color: #000;
}
#pagination li a {
  border-radius: 50%;
  width: 50%;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
#pagination li a.active {
  background-color: #007b85;
  color: #fff;
}
#pagination li a:hover:not(.active) {
  background-color: #ddd;
}
.demo_selftest {
  margin-top: 30px;
}
.demo_selftest h6 {
  color: #007b85;
  font: 14px;
}
.demo_selftest time {
  background-color: #007b85;
  font-size: 14px;
  border-radius: 25px;
  margin-left: 40px;
  text-align: center;
  padding: 4px 10px 3px 10px;
  color: #fff;
  margin-bottom: 30px;
}
.Demo_Close_button i{
  background-color: #007b85;
  color:#fff;
  font-size: 18px;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 8px;
  
  
}
.lightbox {
  /* position: absolute; */
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  transition: 0.3s all;
  opacity: 1;
  pointer-events: auto;
  z-index: 1;
}
.hide-lightbox {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}
.demo_selftest_question {
  background-color: #fff;
  margin-top: 5%;
  border-radius: 20px;
  padding-bottom: 0%;
}
.demo_selftest_question h4 {
  font: normal normal bold 20px/51px Poppins;
  letter-spacing: 0px;
  color: #4a4a4a;
  text-align: center;
  padding-top: 1%;
}
.demo_selftest_question h4 span {
  font: normal normal bold 30px/51px Poppins;
  color: #707070;
  font-weight: 900;
}
.demo_selftest_question span {
  font: normal normal bold 15px/38px Poppins;
  letter-spacing: 0px;
  color: #4a4a4a;
  padding-left: 30px;
  font-weight: 600;
}
.demo_selftest_question p {
  font: normal normal normal 15px/41px Poppins;
  letter-spacing: 0px;
  color: #4a4a4a;
  padding-left: 10%;
}
.demo_selftest_question img {
  width: 30%;
  position: relative;
  left: 20%;
  bottom: 30px;
}
#demo_selftest_question1 p {
  background: #ffceae 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 40%;
  margin-left: 10%;
}
#demo_selftest_question1 span {
  margin-left: 15%;
}
#demo_selftest_question1 h6 {
  margin-right: 50px;
  font: normal normal normal 12px/28px Poppins;
  letter-spacing: 0px;
  color: #4a4a4a;
  margin-top: 3%;
  padding-bottom: 10px;
  font-weight: 500;
}
.demo_selftest_next a {
  color: #fff;
}
.demo_selftest_next button {
  background: #007b85 0% 0% no-repeat padding-box;
  border: none;
  font: normal normal normal 14px/20px Poppins;
  padding: 5px 10px;
  margin: 20px 10px;
  border-radius: 6px;
  color: #fff;
}
/* Selftest QandA b CSS */
.demo_selftest_questiona {
  background-color: #fff;
  margin-top: 5%;
  border-radius: 20px;
  padding-bottom: 0%;
}
.demo_selftest_questiona h6 {
  font: normal normal bold 15px/38px Poppins;
  letter-spacing: 0px;
  color: #4a4a4a;
  padding-left: 40px;
  font-weight: 600;
  padding-top: 2%;
}
.demo_selftest_questiona p {
  background: #ffceae 0% 0% no-repeat padding-box;
  font: normal normal bold 14px/38px Poppins;
  border-radius: 10px;
  width: 45%;
  margin-left: 10%;
  padding: 1px 10px;
}
#demo_selftest_questiona img {
  width: 40%;
  position: relative;
  left: 20%;
  bottom: 0px;
}
#demo_selftest_questiona h6 {
  margin-right: 50px;
  font: normal normal normal 12px/28px Poppins;
  letter-spacing: 0px;
  color: #4a4a4a;
  margin-top: 3%;
  padding-bottom: 10px;
  font-weight: 500;
}
.demo_selftest_nexta a {
  color: #fff;
}
.demo_selftest_nexta button {
  background: #007b85 0% 0% no-repeat padding-box;
  border: none;
  font: normal normal normal 14px/20px Poppins;
  padding: 5px 10px;
  margin: 20px 10px;
  border-radius: 6px;
}
/* PastPaper Edit Card */
.pastpaper_edit_card{
  background: #fff;
  margin: 0 auto;
  width: 70%;
  height: 100%;
  z-index: 1;
  border-radius: 10px;
  align-items: center;;
  position: absolute;
  top: 20px;
  right: 2px;
  left:10px;
  /* border:2px solid #fff; */
  overflow-y:scroll;
}
.pastpaper_edit_card .pastpaper_edit{
  background-color: #5a5eee;
  height: 50px;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
}
.pastpaper_edit h6{
  margin-top: 5px;
  color:#fff;
  font-size: 14px;
  padding-left: 30px;
  font-weight: 600;
}
.pastpaper_edit i{
  color:#fff;
  font-size: 20px;
  font-weight: 900;
  padding-right: 20px;
}
.PastPaper_edit_form{
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 30px 10px 40px 50px;
}
.PastPaper_edit_form1 {
  margin-bottom: 4%;
}
.PastPaper_edit_form1 label {
  font: normal normal normal 14px/28px Poppins;
  letter-spacing: 0px;
  color: #111111;
  font-weight: 500;
}
.PastPaper_edit_form1 select {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 0.65;
  width: 90%;
  font-size: 12px;
  height: 40px;
}
.PastPaper_edit_form1 input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 0.65;
  width: 90%;
  font: normal normal normal 12px/28px Poppins;
  height: 40px;
}
.PastPaper_edit_form1 input::placeholder {
  letter-spacing: 0px;
  color: #777777;
}
.Image_Question_buttons_edit button {
  width: 20%;
  margin: 10px 10px 20px 10px;
  background: #ECECEC 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: 1px solid  #ECECEC;
  color: #666666;
  font: normal normal normal 14px/40px Poppins;
}
.delete_edit_pastpaper{
  float: right;
  color: #fff;
  position: relative;
  top:-25px;
  left:420px;
  font-weight: 800;
  font-size: 20px;
}
/* .Selftest_CreateNewQuestions_data {
  background-color: #5a5eee;
  margin: 10px 20px 0px 0px;
  border-radius: 10px 10px 0px 0px;
  height: 40px;
  color: #fff;
  padding-left: 15px;
  font: normal normal normal 14px/40px Poppins;
} */